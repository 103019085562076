import React, { useState, useEffect } from "react";
import styled from "styled-components";

const katProducts = [
  {
    name: "Banana",
    id: "fruits",
    price: 1.5,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303373/samples/food/fruits/banana_u75yt3.png",
    category: "Fruits",
  },
  {
    name: "Apple",
    id: "fruits",
    price: 2.5,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721385058/samples/food/fruits/apple_rpyycy.png",
    category: "Fruits",
  },
  {
    name: "Grapes",
    id: "fruits",
    price: 2.5,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721372861/samples/food/fruits/CRYTM11_1-2_ud96dg_o0uj52.png",
    category: "Fruits",
  },
  {
    name: "Pineapple",
    id: "fruits",
    price: 2.5,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303372/samples/food/fruits/pineapple_f17iky.png",
    category: "Fruits",
  },
  {
    name: "Mango",
    id: "fruits",
    price: 2.5,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303373/samples/food/fruits/Mango_atejhz.png",
    category: "Fruits",
  },
  {
    name: "Mix Fruits",
    id: "fruits",
    price: 2.5,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721304489/samples/vegetables/fruit_bucket_epjckc.png",
    category: "Fruits",
  },
  {
    name: "Strawberry",
    id: "fruits",
    price: 2.5,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303373/samples/food/fruits/strawberry_nbk8yz.png",
    category: "Fruits",
  },
  {
    name: "Carrot",
    price: 1.0,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721212607/samples/product%20images/vegetables/carrot_aauvm2.png",
    category: "Vegetables",
  },
  {
    name: "Mix Bucket",
    price: 1.8,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721294408/samples/vegetables/vege_z36vzi.png",
    category: "Vegetables",
  },
  {
    name: "Onion",
    price: 1.8,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721294408/samples/vegetables/onion_xo7oad.png",
    category: "Vegetables",
  },
  {
    name: "Broccoli",
    price: 1.8,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721302903/samples/vegetables/Brocooli_2_llpmem.png",
    category: "Vegetables",
  },
  {
    name: "Lettuce",
    price: 1.2,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303234/samples/vegetables/Lettuce3_xsmpoy.png",
    category: "Vegetables",
  },
  {
    name: "Bread",
    price: 3.0,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721302847/samples/dairy%20products/bread_1_r0ibla.png",
    category: "Bakery",
  },
  {
    name: "Croissant",
    price: 2.0,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721302847/samples/dairy%20products/Croissant_vfagrj.png",
    category: "Bakery",
  },
  {
    name: "Milk",
    price: 1.5,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303923/samples/dairy%20products/milk_u14ll7.png",
    category: "Dairy",
  },
  {
    name: "Cheese",
    price: 2.5,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303925/samples/dairy%20products/butter_1_alhsbb.png",
    category: "Dairy",
  },
  {
    name: "Yogurt",
    price: 1.0,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303920/samples/dairy%20products/curd_mif5yy.png",
    category: "Dairy",
  },
  {
    name: "Eggs",
    price: 2.0,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721304282/samples/dairy%20products/eggs_2_d6x0ed.png",
    category: "Dairy",
  },

  {
    name: "Beef",
    price: 7.0,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303672/samples/food/fruits/meat_bvqzmw.png",
    category: "Meat",
  },
  {
    name: "Chicken",
    price: 7.0,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303621/samples/food/fruits/Chicken_gsy990.png",
    category: "Meat",
  },
  {
    name: "Fish",
    price: 7.0,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721303573/samples/food/fruits/fish_zj3kgi.png",
    category: "Meat",
  },
  {
    name: "lentills",
    price: 7.0,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721305773/samples/lentils/lentillls_nzitnm.png",
    category: "Spices",
  },
];

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #e3faec;
  display: flex;
  justify-content: center;
  align-items: space-around;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  margin-top: 25px;
`;

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 90vw;
  overflow-y: auto;
  padding: 20px;
  color: black;
  max-height: 95vh;
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20rem;
  align-items: center;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ProductImage = styled.img`
  object-fit: cover;
  margin-bottom: 10px;
  max-width: 100%;
  height: auto;
`;

const ProductName = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const ProductPrice = styled.p`
  font-size: 1.2em;
  color: #555;
`;

const FilterButton = styled.button`
  background-color: #0b9343;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  padding: 10px;
  font-weight: 300;
  width: 200px;
  margin: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #087f36;
  }
`;

const LoadMoreButton = styled.button`
  background-color: #0b9343;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  padding: 8px 16px;
  font-weight: 300;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #087f36;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const CatProducts = () => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loadedProducts, setLoadedProducts] = useState([]);

  const loadMoreProducts = () => {
    const nextProducts = katProducts.slice(
      loadedProducts.length,
      loadedProducts.length + 8
    );
    setLoadedProducts([...loadedProducts, ...nextProducts]);
  };

  useEffect(() => {
    setLoadedProducts(katProducts.slice(0, 8));
  }, []);

  const filterProducts = (category) => {
    const sectionId = category.toLowerCase(); // Assuming category names are lowercase and match the section IDs
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }

    if (category === "All") {
      setFilteredProducts([]);
    } else {
      const filtered = katProducts.filter(
        (product) => product.category.toLowerCase() === category.toLowerCase()
      );
      setFilteredProducts(filtered);
    }
  };

  return (
    <Wrapper>
      <ContentContainer>
        <ButtonGroup>
          <FilterButton onClick={() => filterProducts("All")}>All</FilterButton>
          <FilterButton onClick={() => filterProducts("Fruits")}>
            Fruits
          </FilterButton>
          <FilterButton onClick={() => filterProducts("Vegetables")}>
            Vegetables
          </FilterButton>
          <FilterButton onClick={() => filterProducts("Bakery")}>
            Bakery
          </FilterButton>
          <FilterButton onClick={() => filterProducts("Dairy")}>
            Dairy
          </FilterButton>

          <FilterButton onClick={() => filterProducts("Meat")}>
            Meat
          </FilterButton>
          <FilterButton onClick={() => filterProducts("Spices")}>
            Lentils & Spices
          </FilterButton>
        </ButtonGroup>
        <Section id="catproducts">
          {(filteredProducts.length > 0
            ? filteredProducts
            : loadedProducts
          ).map((product, index) => (
            <ProductCard key={index}>
              <ProductImage src={product.img} alt={product.name} />
              <ProductName>{product.name}</ProductName>
              {/* <ProductPrice>${product.price.toFixed(2)}</ProductPrice> */}
              <ButtonGroup> Get Now</ButtonGroup>
            </ProductCard>
          ))}
        </Section>
        {loadedProducts.length < katProducts.length && (
          <LoadMoreButton onClick={loadMoreProducts}>Load More</LoadMoreButton>
        )}
      </ContentContainer>
    </Wrapper>
  );
};

export default CatProducts;
