import React from "react";

function Footer() {
  return (
    <footer style={{ backgroundColor: "#087f37", marginBottom: "0" }}>
      <p>
        © 2024 Mushtaq Food Wholesale Ltd. All rights reserved. Website by{" "}
        <a href="https://humaiwebs.com/" style={{ color: "yellow" }}>
          HumAi Webs
        </a>
      </p>
    </footer>
  );
}

export default Footer;
