import React from "react";
import styled from "styled-components";
import { Container as MuiContainer } from "@mui/material";

const Section = styled.div`
  height: 80vh;
  padding: 20px;
  background: url("https://res.cloudinary.com/dzadoxqhj/image/upload/v1720791046/samples/icons/hero-banner_y5a5oe.png")
    no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
  }
`;

const StyledContainer = styled(MuiContainer)`
  align-items: flex-start;
`;

const Container = styled.div`
  width: 40%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  text-align: left;

  @media (max-width: 768px) {
    width: 80%;
    padding: 0;
  }

  h1 {
    font-size: 5rem;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.3rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 1.5rem;
  }
`;

const Button1 = styled.button`
  background-color: #0b9343;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  padding: 10px;
  font-weight: 300;
  width: 200px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: transparent;
    border: 1px solid #0b9343;
  }

  @media (max-width: 768px) {
    width: 50%;
    font-size: 16px;
    font-weight: 500;
  }
`;

const Button2 = styled.button`
  background-color: transparent;
  border: 1px solid #0b9343;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  padding: 10px;
  font-weight: 300;
  width: 200px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0b9343;
  }

  @media (max-width: 768px) {
    width: 50%;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }
`;

function App() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Section id="home">
      <Container>
        <h1>
          100% <span style={{ color: "#0B9343" }}>Organic</span>
        </h1>
        <p>Fresh & Natural Farm Food</p>
        <div className="buttons">
          <Button1 id="button1" onClick={() => scrollToSection("catproducts")}>
            Buy Now
          </Button1>
          <Button2 id="button2" onClick={() => scrollToSection("contact-us")}>
            Get Started
          </Button2>
        </div>
      </Container>
    </Section>
  );
}

export default App;
