import React from "react";
import styled from "styled-components";

const Section = styled.section`
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  background-color: white;
  height: 200px;
  width: 55vw;
  position: absolute;
  top: -5rem;
  border-radius: 25px;
  box-shadow: 1px 2px 26px -5px rgba(0, 0, 0, 0.75);
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  color: #fff;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    width: 80vw;
    top: -3rem;
    padding: 20px;
    display: none;
  }
`;

const CatIconDiv = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  justify-content: space-around;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  background-color: #e3faec;
  padding: 20px;

  img {
    height: 60px;
    width: 60px;
  }

  span {
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const categories = [
  {
    name: "Fruits",
    category: "fruits",
    icon: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1720790347/samples/icons/fruits_ryyan9.png",
  },
  {
    name: "Vegetables",
    category: "vegetables",
    icon: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1720790290/samples/icons/vegetable_qmbwoj.png",
  },
  {
    name: "Bakery",
    category: "bakery",
    icon: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1720790396/samples/icons/bread_ecivqd.png",
  },
  {
    name: "Dairy",
    category: "dairy",
    icon: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1720790458/samples/icons/dairy-products_htedn7.png",
  },
  {
    name: "Eggs",
    category: "eggs",
    icon: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1720790489/samples/icons/eggs_xnihsv.png",
  },
  {
    name: "Meat",
    category: "meat",
    icon: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1720790537/samples/icons/barbecue_n5n9yi.png",
  },
];

const CatIcon = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Section id="catproducts">
      {categories.map((category, index) => (
        <CatIconDiv
          key={index}
          onClick={() => scrollToSection(category.category)}
        >
          <img src={category.icon} alt={`${category.name} Icon`} />
          <span>
            <b>{category.name}</b>
          </span>
        </CatIconDiv>
      ))}
    </Section>
  );
};

export default CatIcon;
