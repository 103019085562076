import React, { useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
  TextField,
  Button as MuiButton,
  MenuItem,
} from "@mui/material";
import { CountryDropdown } from "react-country-region-selector";
import emailjs from "emailjs-com"; // Import EmailJS

const AddressField = styled(TextField)`
  && {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #e3faec;
`;

const Section = styled.section`
  display: flex;
  justify-content: space-between;

  color: #000;
  height: 100vh;
  width: 80vw;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 40px 20px;
  }
`;

const ContactForm = styled.div`
  flex: 1;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  label-background: transparent;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
`;

const FormHeading = styled.h2`
  margin-bottom: 25px;
`;

const FormGrid = styled(Grid)`
  && {
    gap: 20px;
  }
`;

const ContactDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const DetailHeading = styled(Typography)`
  && {
    margin-bottom: 10px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const IconText = styled(Typography)`
  && {
    margin-left: 10px;
  }
`;

const Button = styled(MuiButton)`
  && {
    background-color: #0b9343;
    color: white;

    &:hover {
      background-color: #087f37;
    }
  }
`;

const AddressContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContactImg = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  img {
    width: 100%;
    max-width: 700px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 45px;

    @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Message = styled(Typography)`
  margin-top: 20px;
  color: ${(props) => (props.success ? "green" : "red")};
`;

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    country: "United Kingdom",
    phone: "",
    message: "",
  });
  const [isLoading, setIsloading] = useState(false);

  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (val) => {
    setFormData({ ...formData, country: val });
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = e.target.value.replace(/\D/g, "");
    setFormData({ ...formData, phone: formattedPhoneNumber });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required.";
    if (!formData.category) tempErrors.category = "Category is required.";
    if (!formData.email) tempErrors.email = "Email is required.";
    if (!formData.phone) tempErrors.phone = "Phone Number is required.";
    if (!formData.address1) tempErrors.address1 = "Address Line 1 is required.";
    if (!formData.city) tempErrors.city = "City is required.";
    if (!formData.zip) tempErrors.zip = "Zip Code is required.";
    if (!formData.message) tempErrors.message = "Message is required.";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const postData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        "https://crm-humaiwebs.co.uk/api/mfw/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setSubmissionStatus({
        success: result.status,
        message: result.message,
      });
    } catch (error) {
      setSubmissionStatus({
        success: error.status,
        message: error.message,
      });
    } finally {
      setIsloading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      postData();
      // emailjs
      //   .send(
      //     "service_v2o6u86",
      //     "template_4q4z7ki",
      //     formData,
      //     "FveplCNQ8p_c_jP-e"
      //   )
      // .then(
      //   (result) => {
      //     console.log(result.text);
      //     setSubmissionStatus({
      //       success: true,
      //       message: "Message sent successfully!",
      //     });
      setFormData({
        name: "",
        category: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        zip: "",
        country: "United Kingdom",
        phone: "",
        message: "",
      });
      setIsloading(false);
    } else {
      setSubmissionStatus({
        success: false,
        message: "Please fill all required fields.",
      });
    }
  };

  return (
    <Wrapper>
      <Section id="contact-us">
        <ContactImg>
          <img
            src="https://res.cloudinary.com/dzadoxqhj/image/upload/v1721389109/samples/food/fruits/fruits_nd_vegetables_v60ynq.png"
            alt="Sample Food"
            style={{ height: "76vh" }}
          />
        </ContactImg>
        <ContactForm>
          <FormHeading>Book Your Order Today</FormHeading>
          <form onSubmit={handleSubmit}>
            <FormGrid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  size="small"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  size="small"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Category"
                  variant="outlined"
                  size="small"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  error={!!errors.category}
                  helperText={errors.category}
                >
                  <MenuItem value="">Select Category</MenuItem>
                  <MenuItem value="Fruit & Vegetables">
                    Fruit & Vegetables
                  </MenuItem>
                  <MenuItem value="Lentils & Spices">Lentils & Spices</MenuItem>
                  <MenuItem value="Meat, Fish & Dairy">
                    Meat, Fish & Dairy
                  </MenuItem>
                  <MenuItem value="Dry Products">Dry Products</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <AddressContainer>
                  <AddressField
                    label="Address Line 1"
                    variant="outlined"
                    size="small"
                    name="address1"
                    value={formData.address1}
                    onChange={handleChange}
                    error={!!errors.address1}
                    helperText={errors.address1}
                  />
                  <AddressField
                    label="Address Line 2"
                    variant="outlined"
                    size="small"
                    name="address2"
                    value={formData.address2}
                    onChange={handleChange}
                  />
                </AddressContainer>
              </Grid>
              <Grid item xs={12}>
                <AddressContainer>
                  <TextField
                    fullWidth
                    label="City"
                    variant="outlined"
                    size="small"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    error={!!errors.city}
                    helperText={errors.city}
                  />
                  <TextField
                    fullWidth
                    label="Zip Code"
                    variant="outlined"
                    size="small"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                    error={!!errors.zip}
                    helperText={errors.zip}
                  />
                </AddressContainer>
              </Grid>
              <Grid item xs={12}>
                <CountryDropdown
                  value={formData.country}
                  onChange={handleCountryChange}
                  style={{
                    width: "100%",
                    padding: "8px",
                    fontSize: "16px",
                    background: "transparent",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  name="phone"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Message"
                  variant="outlined"
                  size="small"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  error={!!errors.message}
                  helperText={errors.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Submit Form"}
                </Button>
              </Grid>
            </FormGrid>
          </form>
          {submissionStatus.message && (
            <Message success={submissionStatus.success}>
              {submissionStatus.message}
            </Message>
          )}
        </ContactForm>
      </Section>
    </Wrapper>
  );
}

export default ContactUs;
