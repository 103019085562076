import React from "react";
import styled from "styled-components";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import GoogleMapComponent from "../components/GoogleMapComponent";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #e1e5e2;
`;

const Section = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #000;
  height: 100vh;
  width: 80vw;

  h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    border-bottom: 2px solid #cccccc;

    @media (max-width: 768px) {
      font-size: 1.6rem;
    }
  }

  p {
    margin-bottom: 10px;
    font-size: 20px;
    border-bottom: 1px solid #cccccc;

    @media (max-width: 768px) {
      font-size: 15px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 40px 20px;
  }
`;

const locations = [
  {
    name: "7 Alumrock Road, Birmingham",
    location: { lat: 52.4922, lng: -1.8749 },
  },
  {
    name: "2 Tennis Street, Seymour Grove, Old Trafford",
    location: { lat: 53.4658, lng: -2.2876 },
  },
  {
    name: "2 Crescent Road, Cheetham Hill, Manchester",
    location: { lat: 53.5054, lng: -2.2387 },
  },
  {
    name: "169 Great Horton Road, Bradford, West Yorkshire",
    location: { lat: 53.7914, lng: -1.7643 },
  },
  {
    name: "Wilmslow Road / 401 Great Western Street, Rusholme, Manchester",
    location: { lat: 53.4601, lng: -2.2277 },
  },
  {
    name: "360 Coventry Road, Small Heath, Birmingham",
    location: { lat: 52.4684, lng: -1.8427 },
  },
  {
    name: "Woodhead Road, Bradford, West Yorkshire",
    location: { lat: 53.7918, lng: -1.7815 },
  },
  {
    name: "Lower Tweedale Street, Rochdale",
    location: { lat: 53.6134, lng: -2.1595 },
  },
  {
    name: "24 Slade Ln, Levenshulme, Manchester",
    location: { lat: 53.4411, lng: -2.2033 },
  },
];

const Location = () => {
  return (
    <Wrapper>
      <Section id="location">
        <div>
          <h2>Our Registered Office:</h2>
          <p>46 Houghton Place Bradford, BD1 3RG, UK</p>
          <p>+44 07453884352</p>
          <p>mushtaqfoodsltd@gmail.com</p>
          <h2 style={{ marginTop: "25px" }}>Our Service Locations:</h2>
          {locations.map((loc, index) => (
            <p key={index}>{loc.name}</p>
          ))}
        </div>
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              className="gmap_iframe"
              width="100%"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=46%20Houghton%20Place%20Bradford,%20BD1%203RG,%20UK&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>
      </Section>
    </Wrapper>
  );
};

export default Location;
