import React, { useState } from "react";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  background-color: #0b9343;
  z-index: 999;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  img {
    max-width: 250px;
    height: auto;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    img {
      max-width: 200px;
      height: auto;
    }
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;

    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    background-color: #fff;

    padding: 20px;
    transform: ${(props) =>
      props.isOpen ? "translateX(0)" : "translateX(100%)"};
    transition: transform 0.3s ease-in-out;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  }
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ListItem = styled.li`
  margin-left: 20px;
  font-size: 18px;
  color: white;
  cursor: pointer;

  @media (max-width: 768px) {
    color: black;
    margin-left: 0;
    margin-bottom: 10px;
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

const Button = styled.button`
  // background-color: #0b9343;
  background-color: #e3faec;
  border: none;
  border-radius: 5px;
  color: #0b9343;
  margin-left: 8px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const MenuToggle = styled.div`
  display: none;

  @media (max-width: 768px) {
    color: white;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 10px;
  }
`;

const CloseButton = styled.div`
  display: none;
  align-self: flex-end;
  margin-bottom: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    align-self: flex-end;
    margin-top: 10px;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setIsOpen(false);
    }
  };

  return (
    <Container>
      <LogoContainer>
        <img
          src="https://res.cloudinary.com/dzadoxqhj/image/upload/v1721293919/logo-01_qgd9yw.png"
          alt="Logo"
        />
      </LogoContainer>
      <MenuToggle onClick={handleToggle}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </MenuToggle>
      <Links isOpen={isOpen}>
        <CloseButton onClick={handleToggle}>
          <CloseIcon />
        </CloseButton>
        <List>
          <ListItem onClick={() => scrollToSection("home")}>Home</ListItem>
          <ListItem onClick={() => scrollToSection("about-us")}>
            About Us
          </ListItem>
          <ListItem onClick={() => scrollToSection("products")}>
            Products
          </ListItem>
          <ListItem onClick={() => scrollToSection("location")}>
            Locations
          </ListItem>
        </List>
        <Icons>
          <Button onClick={() => scrollToSection("contact-us")}>
            Contact Us!
          </Button>
        </Icons>
      </Links>
    </Container>
  );
};

export default Navbar;
