import React from "react";
import "./App.css";

import styled from "styled-components";
import Header from "./components/Header";
import Home from "./sections/Home";
import AboutUs from "./sections/AboutUs";
import History from "./sections/History";
import ContactUs from "./sections/ContactUs";
import Footer from "./components/Footer";
import Products from "./sections/Products";
import Location from "./sections/Location";
import CatProducts from "./sections/CatProducts";
import CatIcon from "./components/CatIcon";

const Container = styled.div`
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  color: #fff;
  background-size: cover;
`;

function App() {
  return (
    <div>
      <Header />
      <Container>
        <Home />
        <AboutUs />
        <Products />

        <CatProducts />
        <Location />
        <ContactUs />

        <Footer />
      </Container>
    </div>
  );
}

export default App;
