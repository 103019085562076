import React from "react";
import styled from "styled-components";
import CatIcon from "../components/CatIcon";

const Section = styled.section`
  height: 100vh;
  padding: 20px;
  background-color: #e3faec;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 10px;
  }
`;

const Container = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    height: auto;
    gap: 10px;
  }
`;

const Description = styled.div`
  height: 300px;
  width: 60vw;
  display: flex;
  color: black;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  text-align: center;
  margin-top: -15rem;

  h2 {
    font-size: 4rem;
    color: #0b9343;
  }
  h3 {
    font-size: 2rem;
  }

  pre {
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  p {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    width: 90vw;
    height: auto;
    gap: 10px;
    margin-top: auto;

    h2 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    pre {
      font-size: 1rem;
    }
    p {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.2rem;
    }
    pre {
      font-size: 0.9rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
`;

function AboutUs() {
  return (
    <Section id="about-us">
      <Container>
        <CatIcon />
        <Description className="Desc">
          <h2>Safe food, Now traceable</h2>
          <h3>Your food is now traceable!</h3>
          <pre>
            We are revolutionizing the way the world consumes fruits &
            vegetables. With the help of our technology, your food is now
            completely traceable. Get insights about your produce, farmer &
            farming methods just by scanning a QR code!.
          </pre>
          <pre>
            Our farming community follow good agricultural practices under our
            regular supervision. Moreover, the produce is regularly tested for
            pesticides & strict produce out there.
          </pre>
          <p>Everybody’s switching to a healthy lifestyle with </p>
          <h3>Mushtaq Food Wholesale Ltd.</h3>
          <h2>Call Us Now !</h2>
          <h3>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="tel:+44 07453884352"
            >
              +44 07453884352
            </a>
          </h3>
        </Description>
      </Container>
    </Section>
  );
}

export default AboutUs;
