import React from "react";
import styled from "styled-components";

const Section = styled.section`
  height: 100vh;
  padding: 20px;
  background-color: #fff;
  color: #0b9343;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 3rem;
    color: #0b9343;
  }

  h3 {
    font-size: 2rem;
    color: black;
  }

  p {
    font-size: 1rem;
    color: #000;
  }
`;

const TextContainer = styled.div`
  max-width: 1000px;
`;

const Container = styled.div`
  margin: 20px 0;
`;

const DeliveryList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #000;

  li {
    margin: 5px 0;
  }
`;

function History() {
  return (
    <Section id="history">
      <TextContainer>
        <h2>Safe food, Now traceable</h2>
        <h3>Your food is now traceable!</h3>
        <p>
          Your food is now traceable! We are revolutionizing the way the world
          consumes fruits & vegetables. With the help of our technology, your
          food is now completely traceable. Get insights about your produce,
          farmer & farming methods just by scanning a QR code! Our farming
          community follows good agricultural practices under our regular
          supervision. Moreover, the produce is regularly tested for pesticides
          & strict quality standards are maintained. Everybody’s switching to a
          healthy lifestyle with Mushtaq Food Wholesale Ltd.
        </p>
        <br />
        <hr />

        <h3>Delivery Areas in London</h3>
        <Container
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            margin: "20px 0",
          }}
        >
          <DeliveryList>
            <li>Central London</li>
            <li>East London</li>
            <li>West London</li>
            <li>North London</li>
            <li>South London</li>
          </DeliveryList>
          <DeliveryList>
            <li>Central London</li>
            <li>East London</li>
            <li>West London</li>
            <li>North London</li>
            <li>South London</li>
          </DeliveryList>{" "}
          <DeliveryList>
            <li>Central London</li>
            <li>East London</li>
            <li>West London</li>
            <li>North London</li>
            <li>South London</li>
          </DeliveryList>
        </Container>
      </TextContainer>
    </Section>
  );
}

export default History;
