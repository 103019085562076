import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button as MuiButton,
} from "@mui/material";
import styled from "styled-components";

const products = [
  {
    id: 1,
    name: "Fruits & Vegetables",
    price: 20,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721384250/samples/food/fruits/fruits_nd_vegetables_uafrud.jpg",
    weight: 50,
  },
  {
    id: 2,
    name: "Lentils & Spices",
    price: 30,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721384719/samples/food/fruits/spicess_sjzk9s.jpg",
    weight: 30,
  },
  {
    id: 3,
    name: "Meat, Fish & Dairy",
    price: 40,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721384762/samples/food/fruits/image_2024_07_19T10_18_34_465Z_ikfkqb.png",
    weight: 100,
  },
  {
    id: 4,
    name: "Dry Products",
    price: 50,
    img: "https://res.cloudinary.com/dzadoxqhj/image/upload/v1721384719/samples/food/fruits/lentils_i8ovdn.jpg",
    weight: 150,
  },
];

const Wrapper = styled.div`
  width: 100%;
  background-color: #e1e5e2;
`;

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  color: black;
  background-color: transparent;
`;

const ProductGridItem = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
  }
`;

const ProductCard = styled(Card)`
  && {
    height: 100%;
    width: 45rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }
  }
`;

const Button = styled(MuiButton)`
  && {
    background-color: #0b9343;
    color: white;

    &:hover {
      background-color: #087f37;
    }
  }
`;

const Products = () => {
  return (
    <Wrapper>
      <Section id="products">
        <Grid container spacing={2}>
          {products.map((product) => (
            <ProductGridItem item xs={12} sm={6} key={product.id}>
              <ProductCard
                style={{ backgroundColor: "#0B9343", color: "white" }}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={product.img}
                  alt={product.name}
                />
                <CardContent>
                  {" "}
                  <Typography variant="h5" component="div">
                    <b> {product.name} </b>
                  </Typography>{" "}
                  <div style={{ marginTop: "10px" }}>
                    {/* <Button variant="contained">{}Contact Now</Button> */}
                  </div>
                </CardContent>
              </ProductCard>
            </ProductGridItem>
          ))}
        </Grid>
      </Section>
    </Wrapper>
  );
};

export default Products;
